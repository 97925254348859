import { device } from "src/styles/breakpoints"
import { grayscale } from "src/styles/colors"
import { sand } from "src/styles/newColors"
import styled from "styled-components"

export const TextContent = styled.div`
  text-align: center;
  h2 {
    margin-bottom: 0.5rem;
  }

  @media ${device.tablet} {
    & {
      max-width: 488px;
      margin: 0 auto;
    }
  }

  @media ${device.desktopLG} {
    & {
      max-width: 772px;
    }

    h2 {
      margin-bottom: 1.5rem;
    }
  }

  @media ${device.desktopXXL} {
    & {
      max-width: 820px;
    }

    h2 {
      margin-bottom: 2.5rem;
    }
  }

  @media ${device.desktopXXLL} {
    & {
      max-width: 925px;
    }

    h2 {
      margin-bottom: 2.5rem;
    }
  }
`

export const CarouselBox = styled.div`
  ul {
    list-style: none;
    display: flex;
    justify-content: space-around;
  }

  .carousel {
    background-color: ${sand};
    border-radius: 24px;
    padding: 1rem;
    padding-top: 1.5rem;
    width: 100%;
    max-width: 195px;
    margin-bottom: 1rem;
    position: relative;
    text-align: center;
    height: 195px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    & .carousel-item__title {
      font-family: 'Citrina', Helvetica, sans-serif;
      font-size: 16px;
      line-height: 20px;
      font-weight: 600;
      margin-bottom: 1rem;
    }
    & .carousel-item__body {
      font-size: 14px;
      line-height: 17px;
    }

    & .carousel-tag {
      position: absolute;
      display: grid;
      grid-template-columns: 1fr auto;
      top: -18px;
      left: 50%;
      transform: translateX(-50%);
      align-items: center;

      width: 65px;
      height: 36px;
      padding-left: 0.5rem;
      border-radius: 1.5rem;
      background-color: ${grayscale[400]};
      color: #fff;

      font-size: 14px;
      line-height: 16px;
      font-weight: 600;
    }
    & .carousel-tag span:last-child{
      height: 36px;
      width: 36px;
    }
  }

  .carousel--linked::after {
    content: '';
    position: absolute;
    display: inline-block;
    width: 60%;
    background-color: ${sand};
    height: 2px;
    right: -60%;
    top: 50%;
  }


  .react-multi-carousel-track {
    padding-bottom: 1.5rem !important;
  }

  @media ${device.tablet} {
    & {
      margin-top: 2.5rem;
    }
  }

  @media ${device.desktopLG} {
    .carousel {
      min-width: 243px;
      height: 243px;
      padding: 1.5rem;
      padding-top: 2rem;
      & .carousel-item__title {
        font-size: 18px;
        line-height: 22px;
      }
      & .carousel-item__body {
        font-size: 16px;
        line-height: 20px;
      }
      & .carousel-tag {
        width: 84px;
        height: 43px;
        font-size: 19px;
        line-height: 23px;
        img {
          height: 30px;
        }
      }
      & .carousel-tag span:last-child{
        height: 43px;
        width: 43px;
      }
    }
    .carousel--linked::after {
      width: 132px;
      right: -50%;
    }
  }

  @media ${device.desktopXXL} {
    .carousel {
      min-width: 302px;
      height: 315px;
      padding: 33px;
      padding-top: 43px;
      & .carousel-item__title {
        font-size: 24px;
        line-height: 28px;
      }
      & .carousel-item__body {
        font-size: 20px;
        line-height: 24px;
      }
      & .carousel-tag {
        width: 92px;
        height: 48px;
        font-size: 20px;
        line-height: 25px;
      }
      & .carousel-tag span:last-child{
        height: 48px;
        width: 48px;
      }
    }
    .carousel--linked::after {
      width: 152px;
    }
  }

  @media ${device.desktopXXLL} {
    .carousel--linked::after {
      width: 232px;
      right: -57%;
    }
  }
`

export const CtaBox = styled.div`
  margin: 0 auto;
  @media ${device.tablet} {
    max-width: 265px;
  }

  @media ${device.desktopLG} {
    max-width: 295px;
  }

  @media ${device.desktopXXL} {
    max-width: 456px;
  }
`
